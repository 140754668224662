<template>
  <svg viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.52365 12.255C1.52365 12.9711 2.10436 13.5521 2.82073 13.5521H12.3326C13.049 13.5521 13.6297 12.9714 13.6297 12.255V4.90491H1.52365V12.255ZM4.98252 6.95862C4.98252 6.78027 5.12844 6.63435 5.30679 6.63435H9.84655C10.0249 6.63435 10.1708 6.78027 10.1708 6.95862V7.1748C10.1708 7.35314 10.0249 7.49906 9.84655 7.49906H5.30679C5.12844 7.49906 4.98252 7.35314 4.98252 7.1748V6.95862ZM13.6297 1.44604H1.52365C1.04563 1.44604 0.658936 1.83274 0.658936 2.31076V3.60784C0.658936 3.84563 0.853173 4.0402 1.09129 4.0402H14.062C14.2998 4.0402 14.4944 3.84563 14.4944 3.60784V2.31076C14.4944 1.83274 14.108 1.44604 13.6297 1.44604Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconArchive'
}
</script>
